(function($) {

	/* 
	 * VARIABLES
	 */
	var resizeTimer;
	var recentProjectsInitialized = false;

	/* 
	 * FUNCTIONS
	 */
	
	function initRecentProjects(){
		
		// Homepage - recent projects slider
		// ---------------------------------
		
		if (Foundation.MediaQuery.atLeast('large')) {
			if(recentProjectsInitialized){
				$('.recent-projects.slick').slick('unslick');
			}
		}else if(!recentProjectsInitialized){
			
			if(Foundation.MediaQuery.atLeast('medium')){
				
			}else{
				
			}
			
			$('.recent-projects.slick').slick({
				infinite : false,
				slidesToScroll: 1,
				prevArrow : null,
				nextArrow : null,
				centerPadding: '0',
				slidesToShow: 2,
				rows: 0,
				centerMode: false,
				responsive: [
				    {
				      breakpoint: 800,
				      settings: {
				    	  slidesToShow: 1,
				    	  centerMode: true,
				      }
				    },
				  ]
			});
		}
		
		// ---------------------------------
	}
	
	function initIntroduction(){
		
		// Page introduction
		// -----------------
		
		if( $('.page-introduction').length && $('.project-content').length && !$('.introduction-image').length ){
			if( $('.project-content').find('.project-block').eq(0).hasClass('text-block') ){
				$('.page-introduction').each(function(){
					
					if( Foundation.MediaQuery.atLeast('large') ){
						var $text_element = $(this).find('.introduction-text').eq(0);
						var $container_height = $text_element.outerHeight() - 280;
						$(this).css('height', $container_height + 'px');
					}else{
						$(this).css('height','auto');
					}
					
				});
			}
		}
	}

	function onResizeStart() {
	}

	function onResizeStop() {
		initIntroduction();
		initRecentProjects();
	}
	
	function activateLoadMore(bool){
		if(bool){
			$('a.load-more').removeClass('disabled');
		}else {
			$('a.load-more').addClass('disabled');
		}
	}
    
    function loadMoreButton(){
    	
    	$(document).on('ready', '.scale-images', function() {
	    	$( this ).find('img').each(function(){
				var interchange = new Foundation.Interchange($(this));
			});
		});
		
		$(document).on('click', 'a.load-more', function(event){
			event.preventDefault();
			
			var $button = $(this);
			
			if( $button.hasClass( 'no-more' ) || $button.hasClass('disabled') ) return;
			
			activateLoadMore(false);
			
			var $container = $button.attr('data-container');
			var $next_page_url = $button.attr('data-next-url');
			var $button_replace = $button.attr('data-button-replace');
			var $button_text = $button.attr('data-button-text');
			
			$.ajax({
	            type        : 'GET',
	            url         : $button.attr('href'),
	            dataType    : 'html', 
	            cache       : false,
	        })
	        .done(function(data, state) {
	        	
	        	var $more = $( data ).find( $container + ' .projects-gallery-item' );
	        	var $new_button = $( data ).find( $container + ' .load-more' );
	        	
				$('#projects-gallery').append( $($more) );
				
				$('#projects-gallery').imagesLoaded(function() {
					$button.remove();
					$('#projects-gallery').append( $($more) ).masonry( 'appended', $($more) );
					$('#projects-gallery').append( $($new_button) );
		        });
				
				new Foundation.Equalizer( $( $container ) ).applyHeight();
				
	        });
			
		});
		
	}

	function init() {
		loadMoreButton();

		
		$(window).bind('resize', function(e) {
			onResizeStart();
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(onResizeStop, 50);
		});

		$(window).on('load', function() {
			onResizeStop();

			// Cookienotice

	    	if($('.cn-more-info').length){
		    	$('.cn-more-info').removeClass('border-button')
		    	$('.cn-more-info').appendTo('#cn-notice-text');
		   	}
		});


		
		
		// Navigation
		// ----------
		
		$('.animated-hamburger').on('click', function() {
			$(this).toggleClass('open');
			$('.top-bar').toggleClass('menu-is-open');
		});

		$('#responsive-menu .menu-item a').on('click', function() {
			$('.menu-icon-wrapper > span').foundation('toggleMenu');
			$('.animated-hamburger').toggleClass('open');
		});
		
		// ----------

		
		// Homepage - featured projects slider
		// -----------------------------------

		$('.featured-projects.slick').slick({
			slide: '.scale-images',
			infinite : true,
			slidesToShow : 1,
			dots : true,
			slide: '.slick-slide',
			rows: 0,
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: false,
			pauseOnFocus: false,
			prevArrow : null,
			nextArrow : null,
		});
		
		$('.featured-projects.slick').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			$('.featured-project-info li').hide();
			$('.featured-project-info li:eq( ' + nextSlide + ' )').show();
		});
		
		$('.featured-project-info li:first-child').show();
		
		// -----------------------------------
		

		// Homepage - categories slider
		// ----------------------------
		
		$('.project-categories-info-slider.slick').slick({
			infinite : true,
			slidesToShow : 1,
			arrows : false,
			dots : true,
//			dots : false,
			rows: 0,
//			fade : true,
			asNavFor : '.project-categories-image-slider.slick',
		});
		
		$('.project-categories-image-slider.slick').slick({
			slidesToShow : 1,
			asNavFor : '.project-categories-info-slider.slick',
			dots : false,
			rows: 0,
			arrows : false,
		});
		
		// ----------------------------
		
		// Projects page - masonry
		// -----------------------
	
		if( $('#projects-gallery').length ){
			$('#projects-gallery').imagesLoaded(function() {
				$('#projects-gallery').masonry({
	                itemSelector: '.projects-gallery-item',
	                gutter: '.projects-gallery-gutter-sizer',
	                columnWidth : '.projects-gallery-sizer',
	                percentPosition: true,
	            });
	        });
	        
		}
		
		// -----------------------
		
		// Project detail page - masonry
		// -----------------------
	
		if( $('.gallery-block').length ){
			$('.gallery-block').each(function(i, elem) {
				var $containter = $(elem);
				$containter.imagesLoaded(function() {
					$containter.masonry({
						itemSelector : '.project-gallery-item',
						gutter : '.project-gallery-gutter-sizer',
						columnWidth : '.project-gallery-sizer',
						percentPosition : true,
					});
				});
			});
		}
		
		// -----------------------
	}

	/* 
	 * EVENTS
	 */
	$('.recent-projects.slick').on('init', function(event, slick){
		recentProjectsInitialized = true;
	}).on('destroy', function(event, slick){
		recentProjectsInitialized = false;
	});
	
	// $(document).on('load', function(){
	// });

	init();
	
})(jQuery);

/* FOUNDATION INFO:
 * ----------------
 * 
 * Foundation.MediaQuery.current => 'small', 'medium', etc.
 * 
 * if (Foundation.MediaQuery.atLeast('medium')) {
 * 		True if medium or large
 * 		False if small
 * }
 */

