(function($) {
	
	$.ajaxSetup({ cache: false });
	
	var resizeTimer;
	
	function onResize(){
		scaleImages( $('body') );
	}
	
	function scaleImages( $container ) {
		$container.find(".scale-images").each(function() {
			var image_container = $(this);
			scaleImage(image_container);
		}); 
	}
	
	function scaleImage( $image_container ){
		if( $image_container.hasClass('fadein') ) return;
		
		$image_container.find('img,video').each(function() {
			var $image = $(this);
			$image.removeClass('scale-reverse');
			
			if( $image.height() ==  0) {
				setTimeout(function(){
					scaleImage($image_container);
				}, 50);
			}else{
						
				var $image_smaller_height = $image_container.height() >= $image.height();
				var $image_landscape = ( $image.width() - $image.height() ) >= 0;
				var $container_landscape = ( $image_container.width() - $image_container.height() ) >= 0;
				
				if( $image_landscape && !$container_landscape ){
					$image.addClass('scale-reverse');
				}else if( $image_landscape && $container_landscape ){
					if( $image_smaller_height ){
						$image.addClass('scale-reverse');
					}else {
						setTimeout(function(){
							scaleImage($image_container);
						}, 50);
					}
				}
				
				$image_container.addClass('fadein');
			}
		});
	}

//    $(document).ready(function(){
//    	init();
//    });
    
	$(document).on('init', 'slick', function(event, slick){ // slick-initialized slick-slider
    	$(this).find(".scale-images").removeClass('fadein');
    	scaleImages($(this));
  	});
	
    $(window).bind('resize orientationchange', function(e) {
    	$('.scale-images').removeClass('fadein');
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(onResize, 50);
	});
    
    $(window).on('load', function(){
    	scaleImages( $('body') );
	});

    $(document).on('replaced.zf.interchange', function(event){
    	var $image = $(event.target);
		var $image_container = $image.closest('.scale-images');
		$image_container.removeClass('fadein');
		
		if( $image_container.length ){
			scaleImage($image_container);
		}
    });
    
    $(document).on('postequalized.zf.equalizer', function(event){
    	var $image = $(event.target);
		var $image_container = $image.closest('.scale-images');
		
		if( $image_container.length ){
			$image_container.removeClass('fadein');
			scaleImage($image_container);
		}else{
			scaleImages( $('body') );
		}
    });
    
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
	});
    
})(jQuery);